import React from "react";

function ImproveMaintenance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect width="53" height="53" fill="#000" rx="5"></rect>
            <path
                fill="#fff"
                d="M27.124 17.435a12.232 12.232 0 00-9.62 4.68A12.313 12.313 0 0019.219 39.1a12.213 12.213 0 0016.978-1.146 12.306 12.306 0 003.154-8.647 12.3 12.3 0 00-3.721-8.417 12.219 12.219 0 00-8.507-3.456zM18.57 24.81a.441.441 0 01.846.188.443.443 0 11-.846-.188zm.837 9.707a.442.442 0 11-.065-.332.444.444 0 01.065.333v-.001zm7.383-14.54a.44.44 0 01.746.445.441.441 0 11-.746-.445zm.656 19.476a.44.44 0 01-.735-.456.44.44 0 01.853.138.441.441 0 01-.118.318zm4.602-4.801a.44.44 0 01-.624 0l-4.615-4.63a.442.442 0 01-.13-.314v-5.963a.443.443 0 01.442-.456.44.44 0 01.441.456v5.78l4.486 4.5a.443.443 0 010 .627zm3.357.2a.44.44 0 01-.573-.388.442.442 0 01.87-.135.443.443 0 01-.297.524zm.3-9.773a.443.443 0 01-.523.342.442.442 0 11.34-.798c.147.101.219.281.182.457zM15.56 19.45l-.947.95a.944.944 0 01-1.582-.425.95.95 0 01.245-.916l3.232-3.243a.943.943 0 011.582.425.952.952 0 01-.245.916l-.947.951 1.283 1.288a14.016 14.016 0 00-1.337 1.342l-1.284-1.288zm6.943-7.502a.95.95 0 01.945-.948h7.307a.944.944 0 01.964.949.95.95 0 01-.963.948h-1.002v3.4c-.623-.122-1.255-.2-1.89-.234v-3.166H26.34v3.168c-.635.036-1.267.115-1.89.239v-3.407h-1.002a.944.944 0 01-.945-.949zm17.953 6.957a2.562 2.562 0 01-.752 1.822l-1.304 1.31a13.713 13.713 0 00-1.18-1.5l1.147-1.152a.68.68 0 00-.477-1.164.676.676 0 00-.48.203l-1.147 1.152c-.47-.429-.97-.824-1.495-1.184l1.306-1.31a2.564 2.564 0 013.628.002c.481.482.752 1.137.753 1.82l.001.002z"
            ></path>
        </svg>
    );
}

export default ImproveMaintenance;

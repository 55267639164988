import React from "react";

function DriveSafety() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect width="53" height="53" fill="#000" rx="5"></rect>
            <path
                fill="#fff"
                d="M36.848 14.416C34.688 12.15 31.678 11 27.893 11h-.133c-5.721.041-11.504 3.3-11.486 9.45v4.202l-3.204 4.167a.328.328 0 00-.054.303.35.35 0 00.216.224l3.078 1.102c.09.6.241 1.654.389 2.673a.495.495 0 00.144 0c.072-.007.133-.045.259-.141.209-.176.486-.286.724-.283.237.004.547.131.724.324.093.096.15.145.237.145.108 0 .177-.076.26-.162.356-.379.968-.41 1.368-.07.025.022.079.073.104.094.191.155.339.134.519-.045.14-.141.317-.237.518-.286.537-.11.89.234 1.177.565a.333.333 0 01-.046.482.372.372 0 01-.508-.044c-.212-.242-.306-.338-.436-.338-.111 0-.169.055-.248.134a1.146 1.146 0 01-.756.32c-.249 0-.512-.12-.703-.296-.122-.113-.183-.155-.273-.155-.101 0-.159.052-.249.142-.198.196-.479.32-.709.324-.234.003-.558-.114-.727-.3-.065-.062-.151-.158-.252-.162-.123-.003-.195.069-.27.148-.188.169-.472.29-.681.296h-.076c.166 1.12.3 2.067.303 2.067.021.148.14.268.295.293 1.71.282 4.785.179 5.844.13.072 1.585.115 3.789.093 4.784a.346.346 0 00.267.345c1.703.382 3.446.572 5.192.568a36.46 36.46 0 005.574-.551.345.345 0 00.291-.345c-.035-1.116-.302-6.998-.302-6.998.828-.689 5.005-4.522 5.577-9.915.3-2.859-.352-6.902-3.085-9.775zm-12.714 9.327a.638.638 0 01-.652-.623c0-.345.292-.624.652-.624.36 0 .651.279.651.623a.638.638 0 01-.651.624zm.277-1.787h-.54l-.644-5.166a.303.303 0 01.27-.341c.014-.004.029-.004.043-.004h1.188a.307.307 0 01.317.296c0 .018 0 .032-.004.049l-.63 5.166zm3.723 1.787a.638.638 0 01-.652-.623c0-.345.292-.624.652-.624.36 0 .652.279.652.623a.638.638 0 01-.652.624zm.274-1.787h-.54l-.649-5.156a.302.302 0 01.263-.341c.018-.003.033-.003.05-.003h1.19a.31.31 0 01.316.303c0 .014 0 .027-.003.041l-.627 5.156zm3.636 1.787a.638.638 0 01-.652-.623c0-.345.292-.624.652-.624.36 0 .652.279.652.623a.638.638 0 01-.652.624zm.274-1.787h-.54l-.641-5.166a.302.302 0 01.263-.341c.018-.004.032-.004.05-.004h1.189a.31.31 0 01.317.303c0 .014 0 .028-.004.042l-.634 5.166z"
            ></path>
        </svg>
    );
}

export default DriveSafety;

import React from "react";

function LowerFuelConsumption() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect width="53" height="53" fill="#000" rx="5"></rect>
            <path
                fill="#fff"
                d="M37.717 16.45c-3.619-.294-7.007-1.828-9.572-4.373l-.658-.685A1.399 1.399 0 0026.5 11c-.362 0-.724.13-.987.392l-.658.685c-2.566 2.545-5.954 4.079-9.572 4.372-.724.066-1.283.653-1.283 1.371v3.948c0 8.289 4.54 15.99 11.81 20.069.196.13.46.163.69.163.23 0 .46-.065.69-.163C34.46 37.757 39 30.057 39 21.768V17.82c0-.718-.56-1.305-1.283-1.37zm-5.197 7.407l-6.152 6.624a1.631 1.631 0 01-.987.457h-.032a1.4 1.4 0 01-.987-.392l-3.454-3.491a1.371 1.371 0 010-1.926 1.398 1.398 0 011.94 0l2.468 2.448 5.164-5.58a1.4 1.4 0 011.94-.098c.56.522.593 1.403.1 1.958z"
            ></path>
        </svg>
    );
}

export default LowerFuelConsumption;

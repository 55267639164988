import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import ImproveMaintenance from "./icons/improveMaintenance";
import DriveSafety from "./icons/driveSafety";
import LowerFuelConsumption from "./icons/lowerFuelConsumption";
import UpdateInsight from "./icons/updateInsight";
import Image from "next/image";

const benefitsList = [
    {
        title: "No Stress",
        description:
            "Renting a truck can be challenging, especially in determining the right size to fit all your furniture and belongings.",
        icon: <DriveSafety />,
    },
    {
        title: "Easy Packing",
        description:
            "Professional movers expertly select appropriate packaging for different items and label each package for easy content identification.",
        icon: <ImproveMaintenance />,
    },
    {
        title: "Injury-Free",
        description:
            "Arrive at your new home without frustration or physical discomfort as the entire move is handled for you.",
        icon: <LowerFuelConsumption />,
    },
    {
        title: "Quick & Efficient",
        description:
            "If you need a quick move, certain companies specialize in fast, efficient relocation services to meet tight deadlines, ensuring both speed and quality.",
        icon: <UpdateInsight />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <div
            className={`flex flex-col xl:flex-row flex-nowrap items-center justify-between mb-12 max-w-7xl px-4 mx-auto ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full md:w-3/5 ">
                <div
                    className={` font-bold mb-8 text-3xl xl:text-4xl pt-11 md:pt-24 mb-12 ${styles["title"]}`}
                >
                    The Advantages of Hiring a Top-Rated Moving Company
                </div>

                {benefitsList.map((benefit) => (
                    <div key={benefit.title}>
                        <div className={`flex items-start mb-5`}>
                            <div className={`w-16 mr-8`}>{benefit.icon}</div>
                            <div>
                                <div
                                    className={`font-bold  text-base xl:text-lg ${styles["title2"]}`}
                                >
                                    {benefit.title}
                                </div>
                                <div
                                    className={`text-sm mb-6 ${styles["brief"]}`}
                                >
                                    {benefit.description}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="relative z-10 w-[230px] h-[216px] lg:w-[460px] lg:h-[432px]">
                <Image
                    src="/moveyouout/assets/images/moving.png"
                    alt="Benefits of Fleet Tracking"
                    layout="fill"
                />
            </div>
        </div>
    );
}
